import $ from "jquery";

$(function(){

    let header = document.querySelector('header');
    const observer = new IntersectionObserver( 
        ([e]) => e.target.classList.toggle("is-stuck", e.intersectionRatio < 1),
        { threshold: [1] }
      );
      
      observer.observe(header);
      
});